import { IExpert } from '@utils/types';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

const Expert = ({ name, summary, image, phone, email, link }: IExpert) => {
	const gatsbyImage = getImage(image?.childImageSharp.gatsbyImageData);

	return (
		<div className="card profile-card">
			{gatsbyImage && (
				<div className="profile-card__image">
					<div className="image-background">
						<GatsbyImage
							image={gatsbyImage}
							alt={name}
							objectFit="cover"
						/>
					</div>
				</div>
			)}
			<div className="profile-card__content">
				<h3 className="profile-card__name">{name}</h3>
				<span className="profile-card__job">{summary}</span>
				{phone && <span className="profile-card__phone">{phone}</span>}
				{email && <span className="profile-card__email">{email}</span>}
				<span className="profile-card__button">View profile</span>
			</div>
			<Link to={link} className="link-coverer profile-card__link">
				View profile
			</Link>
		</div>
	);
};

export default Expert;
