// i18next-extract-mark-ns-start about-us-team

import { LINKS } from '@utils/constants';
import { IExpertFrontmatter } from '@utils/types';
import { graphql } from 'gatsby';
import React from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Deck from '@components/Deck';
import Expert from '@components/Expert';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const TeamPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection className="section__experts" layout="wide">
				<Deck>
					{data.experts.edges.map(
						({ node }: { node: IExpertFrontmatter }, i: number) => (
							<Expert
								key={i}
								name={node.frontmatter.name}
								summary={node.frontmatter.summary}
								image={node.frontmatter.image}
								link={`${LINKS.ABOUT_TEAM}${node.fields.slug}`}
							/>
						)
					)}
				</Deck>
			</PageSection>
		</Page>
	);
};

export default TeamPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["about-us-team", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/about-us/meet-the-team/Realis-Simulation_MeetTheTeam_cropped.jpg" }) {
		...imageBreaker
	}
	experts: allMarkdownRemark(
		filter: { 
			fileAbsolutePath: { regex: "/experts/" } 
			fields: { language: { eq: $language } }
		}				
	) {
		edges {
			node {
				fields {
					slug
				}
				frontmatter {
					name
					summary
					image {
						...imageStandard
					}
				}
			}
		}
	}
}
`;